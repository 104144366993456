<template>
	<div class="disDetails">
		<div style="width: 100%;height: 40px;"></div>
		<div class="container">
			<div class="top-details">
				<h5>{{info.name}}</h5>
				<p class="bankname"></p>
				<div class="today-SY">
					<!-- <div class="process">
						<div v-bind:style="{width: health+'%'}"></div>
					</div> -->
					<div class="progress">
						<span class="progressing"
							v-bind:style=" 'width:' + (info.stock/info.count)*100 +'%' ">
						</span>
					</div>
					<p>今日剩余<span>{{parseInt(info.stock/info.count*100)}}</span>%</p>
				</div>
				<p class="hint">适用于：本门店所有商品</p>
			</div>
			<div class="bot-details">
				<div class="listDeta">
					<div>
						<img src="../../assets/time.png" alt="" />
						<p>{{info.start_time}}-{{info.end_time}}</p>
					</div>
					<!-- <div>
						<img src="../../assets/active.png" alt="" />
						<p>详见活动规则</p>
					</div> -->
					<div>
						<img src="../../assets/guize.png" alt="" />
						<p>详见使用细则</p>
					</div>
				</div>
				<div class="img-detail">
					<img v-if="info.image" :src="imageUrl+info.image" alt="" class="goods-image">
				</div>
				<!--				<van-cell is-link title="活动资询" @click="show = true" />-->
				<!--				<van-action-sheet v-model:show="show" title="客服">-->
				<!--					<div class="content">-->
				<!--						<div style="display: flex;align-items: center;">-->
				<!--							<p style="font-size: 14px;font-weight: 600;">票券编码</p>-->
				<!--							<p style="font-size: 13px; color: #666;margin-left: 10px;">1 2012 0201 2161 820</p>-->
				<!--						</div>-->
				<!--						<div style="display: flex;align-items: center;justify-content: space-between;">-->
				<!--							<p style="font-size: 14px;font-weight: 600;">在线客服</p>-->
				<!--							<img src="../../assets/right.png" alt="" style="width: 18px;height: 18px;">-->
				<!--						</div>-->
				<!--						<div style="display: flex;align-items: center;justify-content: space-between;">-->
				<!--							<p style="font-size: 14px;font-weight: 600;">客服电话<span style="color: #999;font-weight: normal;margin-left: 10px;font-size: 13px;">95516</span></p>-->
				<!--							<img src="../../assets/right.png" alt="" style="width: 18px;height: 18px;">-->
				<!--						</div>-->
				<!--					</div>-->
				<!--				</van-action-sheet>-->
			</div>
		</div>
		<div class="sygz">
			<van-collapse v-model="activeName">
				<van-collapse-item title="使用细则" name="1">
					{{info.detail}}
				</van-collapse-item>
			</van-collapse>
		</div>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import {
		getCouponDetail
	} from '@/api/index'
	import {
		ref
	} from 'vue';
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				health: 0,
				info: [],
				id: '',
				imageUrl: http.imageUrl,
			}
		},
		setup() {
			const show = ref(false);
			const activeName = ref(['1']);
			return {
				show,
				activeName
			};
		},
		mounted() {
			this.id = this.$route.query.id
			this.load()
		},
		methods: {
			load() {
				getCouponDetail({
					id: this.id
				}).then((res) => {
					console.log(res)
					this.info = res.data
				})
			}
			// onSelect(item) {
			// 	// 默认情况下点击选项时不会自动收起
			// 	// 可以通过 close-on-click-action 属性开启自动收起
			// 	this.show = false;
			// 	Toast(item.name);
			// },
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.disDetails {
		width: 100%;
		height: 250px;
		background: linear-gradient(#ee0a24, #fff);

		.container {
			width: 85%;
			height: 450px;
			background-color: #FFFFFF;
			box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			margin: 0 auto;
			position: relative;

			.top-details {
				width: 100%;
				height: 30%;
				border-bottom: dotted 1px #999;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h5 {
					margin: 0;
				}

				.bankname {
					font-size: 13px;
				}

				.today-SY {
					display: flex;
					align-items: center;

					.process {
						width: 90px;
						height: 3px;
						// margin: 0 auto;
						margin: 5px;
						// border: black 4px solid;
						background-color: #1989fa;

						div {
							height: 3px;
							background: #0e5295;
						}
					}

					p {
						margin: 0;
						font-size: 12px;
					}
				}

				.hint {
					font-size: 12px;
				}
			}

			.bot-details {
				width: 100%;
				height: 70%;

				.listDeta {
					margin-left: 15px;
					margin-top: 15px;

					div {
						display: flex;
						align-items: center;
						padding: 3px 0;

						img {
							width: 18px;
							height: 18px;
						}

						p {
							font-size: 12px;
							margin: 0;
							margin-left: 10px;
							color: #666;
						}
					}
				}

				.img-detail {
					width: 100px;
					height: 100px;
					margin: 5px auto;

					img {
						width: 100%;
						height: 100%;
					}
				}

			}
		}

		// .container:after {
		// 	position: absolute;
		// 	content: "";
		// 	width: 20px;
		// 	height: 20px;
		// 	background: #feb7b4;
		// 	border-radius: 100%;
		// 	right: 0;
		// 	top: 30%;
		// 	transform: translate(50%, -50%);
		// }

		// .container:before {
		// 	position: absolute;
		// 	content: "";
		// 	width: 20px;
		// 	height: 20px;
		// 	background: #feb7b4;
		// 	border-radius: 100%;
		// 	left: 0;
		// 	top: 30%;
		// 	transform: translate(-50%, -50%);
		// }
		.sygz {
			width: 85%;
			// height: 100px;
			background-color: #FFFFFF;
			box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			margin: 15px auto;
		}
	}

	.content {
		padding: 16px 16px 80px;
	}

	.progress {
		width: 60px;
		height: 3px;
		background: #ee0a24;
		// border: 1px solid #ecc353;
		border-radius: 10px;
		text-align: center;
		color: #fff;
		margin: 3px 0;

		.progressing {
			position: relative;
			float: left;
			margin: 0 auto;
			height: 3px;
			background: #a80002;
			border-radius: 10px;

			.percentage {
				margin-left: 50px;
			}
		}
	}

</style>
